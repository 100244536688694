import { makeApiRequest } from '@/src/lib/api-request'

import { useUser } from '@/src/store/user-store'
import { useQuery } from '@tanstack/react-query'

export const useUIDQuery = () => {
  const { refreshToken, _hasHydrated } = useUser()
  const { user } = useUser()

  return useQuery({
    queryKey: ['guest', 'uid', { refreshToken }],
    queryFn: async () => {
      const response = await makeApiRequest({
        method: 'GET',
        url: '/user/setcookie_UID',
        data: {
          refresh_token: refreshToken,
        },
        isAuthenticated: user ? true : false,
        sendCredentials: user ? true : false,
        fallbackError: 'Error while fetching uid',
        isV3Api: true
      })

      return response.response.UID
    },
    staleTime: 1000 * 60 * 30,
    // enabled: refreshToken === null && _hasHydrated,
    refetchOnWindowFocus: false,
  })
}
