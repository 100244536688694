import { makeApiRequest } from '@/src/lib/api-request'

import { useToken } from '@/src/store/token-store'
import { useUser } from '@/src/store/user-store'
import { useQuery } from '@tanstack/react-query'

export const useSessionTracking = ({ isUIDSet }: { isUIDSet: boolean }) => {
  const { user, _hasHydrated } = useUser()
  const { accessToken } = useToken()
  return useQuery({
    queryKey: ['session-tracking', { user }],
    queryFn: async () => {
      if (user)
        await makeApiRequest({
          method: 'GET',
          url: '/Analytics_Process/Session_Save',
          data: {},
          isAuthenticated: true,
          fallbackError: 'Error while tracking session',
          sendCredentials: true,
          isV3Api: true,
        })
      else {
        await makeApiRequest({
          method: 'GET',
          url: '/Analytics_Process/Session_Save',
          data: {},
          isAuthenticated: false,
          fallbackError: 'Error while tracking session',
          sendCredentials: true,
          isV3Api: true,
        })
      }
    },
    enabled: isUIDSet || (accessToken != null && _hasHydrated),
    refetchOnWindowFocus: false,
    refetchInterval: 1000 * 10,
  })
}
